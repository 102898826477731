import "../../Audio/AudioPlayer.css";
import {
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import {
  IconButton,
  IconButtonProps,
  Slider,
  SvgIconProps,
} from "@mui/material";
import Stack, { StackProps } from "@mui/material/Stack";

export interface VolumeControlProps {
  muted: boolean;
  setMuted: (muted: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
  StackProps?: StackProps;
  ButtonProps?: IconButtonProps;
  IconProps?: SvgIconProps;
  isLoading:boolean;
}

interface VolumeIconProps extends SvgIconProps {
  volume: number;
  muted?: boolean;
}

const VolumeIcon = ({ volume, muted = false, ...props }: VolumeIconProps) => {
  if (muted) return <VolumeOff {...props} />;
  if (volume === 0) return <VolumeMute {...props} />;
  if (volume > 50) return <VolumeUp {...props} />;
  return <VolumeDown {...props} />;
};

const VolumeControl = ({
  muted,
  setMuted,
  volume,
  setVolume,
  StackProps,
  ButtonProps,
  IconProps,
  isLoading
}: VolumeControlProps) => {
  const handleVolumeChange = (_: Event, value: number | number[]) => {
    setVolume(value as number);
  };

  return (
    <Stack {...StackProps} spacing={2} direction="row" alignItems="center" width={1/4}>
      <IconButton 
      onClick={() => setMuted(!muted)}
      disabled={isLoading}
      {...ButtonProps}>
        <VolumeIcon
          key={`${volume}-${muted}`}
          volume={volume}
          muted={muted}
          {...IconProps}
        />
      </IconButton>
      <Slider
        className="VolumeSlider"
        aria-label="Volume"
        value={volume}
        onChange={handleVolumeChange}
        disabled={isLoading}
      />
    </Stack>
  );
};

export default VolumeControl;
