import {
  HTMLAttributes,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import List from "@mui/material/List";
import { ListItem, Button, Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSites } from "../../hooks/useSite";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { Interactions } from "../../api/interaction";
import Site from "../../types/site/Site";
import MultiSelect from "../../components/common/MultiSelect/MultiSelect";
import { DateTimePicker } from "../../components/common/DateTimePicker/DateTimePicker";
import DurationField from "../../components/common/DurationField/DurationField";
import Typeahead from "../../components/common/Typeahead/Typeahead";
import ClearableTextField from "../../components/common/ClearableTextField/ClearableTextField";
import DnisFilter from "./components/input/DnisFilter";
import Select from "../../components/common/Select/Select";
import { SearchFormValues } from "../../types/search/SearchFormValues";
import FieldLabelContext from "./context/FieldLabelContext";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import RadioButtons from "../../components/common/RadioButtons/RadioButtons";
import { useInteractionTags } from "../../hooks/useInteraction";

export const DEFAULT_SEARCH_VALUES: SearchFormValues = {
  agentName: null,
  sourceId: "",
  interactionId: "",
  masterInteractionId: "",
  device: null,
  direction: null,
  extension: "",
  from: null,
  legalHold: [],
  hasMedia: null,
  maxDuration: null,
  minDuration: null,
  phoneNumber: null,
  dnis: "",
  site: null,
  to: null,
  group: null,
  agentId: "",
  skill: null,
  segmentId: "",
  tags: [],
};

// dayjs plugin for using utc timezone
dayjs.extend(utc);

export interface SearchDrawerFormProps extends HTMLAttributes<HTMLDivElement> {
  customerId: string;
  setSearchValues: (values: SearchFormValues) => void;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<Interactions, Error>>;
  closePlayer: () => void;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  handlePaginationModelChange: (model: any) => void;
}

export default function SearchDrawerForm({
  setSearchValues,
  customerId,
  closePlayer,
  setOpen,
  handlePaginationModelChange,
}: SearchDrawerFormProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDateAndTime, setOpenDateAndTime] = useState<boolean>(false);
  const [openAgent, setOpenAgent] = useState<boolean>(false);
  const [openInteractionInfo, setOpenInteractionInfo] =
    useState<boolean>(false);
  const [openAdvanced, setOpenAdvanced] = useState<boolean>(false);

  const { data } = useSites(customerId);
  const { data: tags = [] } = useInteractionTags(customerId);

  const handleOpenDateAndTime = () => {
    setOpenDateAndTime(!openDateAndTime);
  };

  const handleOpenAgent = () => {
    setOpenAgent(!openAgent);
  };

  const handleOpenInteractionInfo = () => {
    setOpenInteractionInfo(!openInteractionInfo);
  };

  const handleOpenAdvanced = () => {
    setOpenAdvanced(!openAdvanced);
  };

  const form = useForm<SearchFormValues>({
    defaultValues: DEFAULT_SEARCH_VALUES,
  });
  const { control, reset, handleSubmit, watch } = form;

  useEffect(() => {
    const filters: SearchFormValues = { ...watch() }; // Capture current form values
    const tags: string[] = [];
    searchParams.forEach((value, key) => {
      if (key in DEFAULT_SEARCH_VALUES) {
        let parsedValue;
        if (key === "from" || key === "to") {
          parsedValue = dayjs.utc(value).local(); // Handle date formatting (UTC to local)
        } else if (key === "tags") {
          tags.push(value);
        } else {
          try {
            parsedValue = JSON.parse(value); // Handle JSON-compatible values
          } catch (e) {
            parsedValue = value; // Fallback to raw value if not JSON
          }
        }

        filters[key as keyof SearchFormValues] = parsedValue;
      }
    });

    if (tags.length > 0) {
      filters.tags = tags;
    }

    reset(filters);
    setSearchValues(filters); // Set the updated search values
  }, [searchParams, reset, setSearchValues, watch]);

  const submitSearch = useCallback(
    (data: SearchFormValues) => {
      const params = new URLSearchParams();
      Object.entries(data).forEach(([key, value]) => {
        // this line is to handle the legalHold array as otherwise, the URL will show it (even as an empty array)
        if (key === "legalHold" && Array.isArray(value) && value.length > 0) {
          params.set(key, JSON.stringify(value));
        } else if (key === "tags" && Array.isArray(value) && value.length > 0) {
          value.forEach((tag) => {
            params.append(key, String(tag));
          });
        } else if (
          Array.isArray(value) === false &&
          value !== null &&
          value !== undefined &&
          value !== ""
        ) {
          if (key === "from" || key === "to") {
            const dateValue = value as dayjs.Dayjs;
            if (dateValue) {
              params.set(key, dateValue.utc().format("YYYY-MM-DDTHH:mm:ss"));
            }
          } else if (typeof value === "object") {
            params.set(key, JSON.stringify(value)); // Handle other JSON-compatible values
          } else {
            params.set(key, String(value)); // Handle other values (ex: string and number types)
          }
        }
      });

      setSearchParams(params);
      setSearchValues(data);
      closePlayer();
      setOpen(false);
      handlePaginationModelChange({ page: 0, pageSize: 100 });
    },
    [
      setSearchValues,
      closePlayer,
      setOpen,
      setSearchParams,
      handlePaginationModelChange,
    ]
  );

  const { getFieldLabel } = useContext(FieldLabelContext);

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit(submitSearch)}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flex: 1, // This makes the scrollable area take up all available vertical space
            overflowY: "auto",
          }}
        >
          <List
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{ position: "relative", top: 0 }}
              >
                Filter by Category
              </ListSubheader>
            }
          >
            <ListItemButton onClick={handleOpenDateAndTime}>
              <ListItemText primary="Date & Time" />
              {openDateAndTime ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDateAndTime} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <DateTimePicker
                    name="from"
                    label={getFieldLabel("startTime")}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <DateTimePicker
                    name="to"
                    label={getFieldLabel("endTime")}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    control={control}
                    name="minDuration"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <DurationField
                          {...rest}
                          className="min-w-16"
                          control={control}
                          name="minDuration"
                          label={`Min. ${getFieldLabel("durationMs")}`}
                          defaultUnit="second"
                        />
                      );
                    }}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    control={control}
                    name="maxDuration"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <DurationField
                          {...rest}
                          className="min-w-16"
                          control={control}
                          name="maxDuration"
                          label={`Max ${getFieldLabel("durationMs")}`}
                          defaultUnit="second"
                        />
                      );
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>

            <ListItemButton onClick={handleOpenAgent}>
              <ListItemText primary="Agent Detail" />
              {openAgent ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAgent} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <Controller
                    name="agentName"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <Typeahead
                          {...rest}
                          label={getFieldLabel("agentName")}
                          pascalFieldName="AgentName"
                          customerId={customerId}
                          freeSolo
                          virtualized
                          fullWidth
                        />
                      );
                    }}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="agentId"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <ClearableTextField
                        {...field}
                        inputRef={ref}
                        fullWidth
                        name="agentId"
                        label={getFieldLabel("agentId")}
                        size="small"
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="group"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Typeahead
                        {...field}
                        fullWidth
                        label={getFieldLabel("group")}
                        pascalFieldName="Group"
                        customerId={customerId}
                        freeSolo
                        virtualized
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="skill"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Typeahead
                        {...field}
                        fullWidth
                        label={getFieldLabel("skill")}
                        pascalFieldName="Skill"
                        customerId={customerId}
                        freeSolo
                        virtualized
                      />
                    )}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItemButton onClick={handleOpenInteractionInfo}>
              <ListItemText primary="Interaction Information" />
              {openInteractionInfo ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openInteractionInfo} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <Controller
                    name="masterInteractionId"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <ClearableTextField
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          label={getFieldLabel("masterInteractionId")}
                          size="small"
                        />
                      );
                    }}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="segmentId"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <ClearableTextField
                          {...rest}
                          inputRef={ref}
                          fullWidth
                          label={getFieldLabel("segmentId")}
                          size="small"
                        />
                      );
                    }}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="phoneNumber"
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <Typeahead
                          {...rest}
                          pascalFieldName="PhoneNumber"
                          label={getFieldLabel("phoneNumber")}
                          customerId={customerId}
                          freeSolo
                          virtualized
                          fullWidth
                        />
                      );
                    }}
                    control={control}
                  />
                </ListItem>
                <ListItem>
                  <DnisFilter name="dnis" control={control} />
                </ListItem>
                <ListItem>
                  <Controller
                    name="extension"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <ClearableTextField
                        {...field}
                        inputRef={ref}
                        fullWidth
                        label={getFieldLabel("extension")}
                        size="small"
                      />
                    )}
                  />
                </ListItem>

                <ListItem>
                  <Select
                    size="small"
                    name="direction"
                    control={control}
                    fullWidth
                    label={getFieldLabel("direction")}
                    options={["in", "out"]}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="device"
                    control={control}
                    render={({ field }) => {
                      const { ref, ...rest } = field;
                      return (
                        <Typeahead
                          {...rest}
                          size="small"
                          customerId={customerId}
                          pascalFieldName="Device"
                          label={getFieldLabel("device")}
                          virtualized
                          fullWidth
                        />
                      );
                    }}
                  />
                </ListItem>
                <ListItem>
                  <RadioButtons
                    name="hasMedia"
                    label={getFieldLabel("hasMedia")}
                    control={control}
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                      { value: null, label: "Any" },
                    ]}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItemButton onClick={handleOpenAdvanced}>
              <ListItemText primary="Advanced" />
              {openAdvanced ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAdvanced} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <Select
                    fullWidth
                    control={control}
                    name="site"
                    options={data?.sites ?? []}
                    label={getFieldLabel("siteId")}
                    getOptionKey={(option: Site) => option.siteId}
                    getOptionLabel={(option: Site) => option.name}
                    isOptionEqualToValue={(option: Site, value: Site) => {
                      return option.siteId === value.siteId;
                    }}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="interactionId"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <ClearableTextField
                        {...field}
                        inputRef={ref}
                        fullWidth
                        label={getFieldLabel("interactionId")}
                        size="small"
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <MultiSelect
                    fullWidth
                    name="legalHold"
                    label={getFieldLabel("legalHold")}
                    control={control}
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    size="small"
                    limitTags={2}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="sourceId"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <ClearableTextField
                        {...field}
                        inputRef={ref}
                        fullWidth
                        name="sourceId"
                        label={getFieldLabel("sourceId")}
                        size="small"
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <MultiSelect
                    fullWidth
                    name="tags"
                    label={getFieldLabel("tags")}
                    control={control}
                    options={tags}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    size="small"
                    limitTags={10}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
        <Box
          sx={{
            position: "sticky",
            borderTop: "1px solid #e0e0e0",
            marginBottom: "22%",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "background.paper",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{
              m: 1,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              reset(DEFAULT_SEARCH_VALUES);
              submitSearch(DEFAULT_SEARCH_VALUES);
              setSearchParams(new URLSearchParams());
              handlePaginationModelChange({ page: 0, pageSize: 100 });
            }}
            color="secondary"
            variant="contained"
            size="large"
            sx={{
              m: 1,
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
}
