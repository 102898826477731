import {
  InteractionByMediaType,
  InteractionsByDay,
} from "../types/dashboard/Dashboard";
import { getApi } from "./helpers/apiMethods";

export interface GetDashboardDataResponse {
  interactionByType: InteractionByMediaType[];
  interactionsByDay: InteractionsByDay;
  averageDuration: number; // in milliseconds
  countInteractionsExpiring: number;
  countLegalHold: number;
}

export const getDashboard = async (
  customerId: string
): Promise<GetDashboardDataResponse | undefined> => {
  const response = await getApi<GetDashboardDataResponse>(
    `/v1/customers/${customerId}/dashboard`
  );
  return response.data;
};
