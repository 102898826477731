const styles = {
  container: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 5,
    padding: 2,
  },
  messageContainer: {
    marginBottom: 2,
    padding: 1,
    display: "flex",
    flexDirection: "column",
  },
  messageContent: (isAgent) => ({
    padding: 2,
    borderRadius: 1,
    backgroundColor: isAgent ? "#e0f7fa" : "#f5f5f5",
    alignSelf: isAgent ? "flex-end" : "flex-start",
    flexDirection: "column",
  }),
};

export default styles;
