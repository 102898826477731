import { Box, Typography } from "@mui/material";
import { useChatTranscript } from "../../../hooks/useInteraction";
import chatDisplayStyles from "./ChatDisplayStyles";

interface ChatDisplayProps {
  fileUrl: string | undefined;
  agentId: string | undefined;
}

const ChatDisplay = ({ fileUrl, agentId }: ChatDisplayProps) => {
  const {
    data: transcript = [],
    isLoading,
    isError,
    error,
  } = useChatTranscript(fileUrl);

  if (isLoading) return <div>Loading transcript...</div>;
  if (isError) {
    console.error(`Error fetching transcript: ${error.message}`);
    return (
      <div>Error fetching transcript. Please try again or contact support.</div>
    );
  }

  return (
    <Box sx={chatDisplayStyles.container}>
      {transcript.map((message) => {
        const isAgent = message.participantId === agentId;
        const setAlignment = isAgent ? "right" : "left";

        return (
          <Box key={message.id} sx={chatDisplayStyles.messageContainer}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align={setAlignment}
            >
              {message.displayName} ({message.participantRole})
            </Typography>
            <Box sx={chatDisplayStyles.messageContent(isAgent)}>
              {message.type === "MESSAGE" ? (
                <Typography
                  variant="body1"
                  color="text.primary"
                  align={setAlignment}
                >
                  {message.content}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align={setAlignment}
                >
                  {message.contentType.includes("joined")
                    ? `${message.displayName} has joined the chat`
                    : `${message.displayName} has left the chat`}
                </Typography>
              )}
              <Typography
                variant="caption"
                color="text.secondary"
                align={setAlignment}
              >
                {new Date(message.absoluteTime).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChatDisplay;
