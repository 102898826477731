export const sortStringsNaturally = (a: string, b: string): number => {
  const regex = /(\d+)|(\D+)/g;

  const getParts = (str: string) =>
    str.match(regex)?.map((s) => (/\d/.test(s) ? parseInt(s, 10) : s)) ?? [];

  const partsA = getParts(a);
  const partsB = getParts(b);

  // Edgecase - String contained only numbers
  if (partsA.length === 1 && partsB.length === 1) {

    if(typeof partsA[0] == 'number' && typeof partsB[0] == 'number'){
      return partsA[0] - partsB[0];
    }

    return partsA < partsB ? -1 : 1;
    
  }
  
  for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    if (partsA[i] !== partsB[i]) {
      return partsA[i] < partsB[i] ? -1 : 1;
    }
  }
  return partsA.length - partsB.length;
};
