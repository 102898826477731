const emailDisplayStyles = {
  paper: {
    padding: 3,
    maxWidth: '100%',
    margin: 'auto',
    marginBottom: 10,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 1,
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  subject: {
    flexShrink: 1,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timestamp: {
    whiteSpace: 'nowrap',
    marginLeft: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  label: {
    marginRight: 1,
    whiteSpace: 'nowrap',
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldedValue: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
    marginBottom: 2,
    textDecoration: 'underline',
  },
  messageBody: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
};

export default emailDisplayStyles;
