import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { AxiosError } from "axios";
import { logout } from "../api/auth";
import { Config, loadConfig } from "../configuration/config";
import SSOProvider from "../types/auth/SSOProvider";
import { TokenPayload } from "../context/AuthContext";

export type LoginRequest = {
  email: string;
  password: string;
};

export type SetPasswordRequest = {
  email: string;
  currentPassword: string;
  password: string;
  requiredAttributeData: any;
};

export type ResetPasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export class AuthError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
    this.name = "AuthError";
  }
}

export default class AuthService {
  private static _instance: AuthService;
  private _userPool: CognitoUserPool;

  constructor(config: Config) {
    this._userPool = new CognitoUserPool({
      UserPoolId: config.CognitoUserPoolId,
      ClientId: config.CognitoAppClientId,
    });
  }

  public getCurrentUser(): CognitoUser | null {
    return this._userPool.getCurrentUser();
  }

  public async getIdToken(): Promise<CognitoIdToken | undefined> {
    return await new Promise((resolve, reject) => {
      const user = this.getCurrentUser();
      if (!user) {
        reject(new AuthError("Failed to get current user.", 401));
        return;
      }
      user.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (!!error || !session?.isValid()) {
            reject(
              error ?? new AuthError("Failed to get current session.", 440)
            );
            return;
          }
          const idToken = session.getIdToken();
          if (!idToken) {
            reject(new Error("Failed to get id token."));
            return;
          }
          resolve(idToken);
        }
      );
    });
  }

  private authenticateUser(
    user: CognitoUser,
    authenticationDetails: AuthenticationDetails,
    onSuccess: (session: CognitoUserSession) => void,
    onFailure: (error: unknown) => void,
    onNewPasswordRequired: (
      userAttributes: unknown,
      requiredAttributes: unknown
    ) => Promise<void>
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      user.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          onSuccess(session);
          resolve(true);
        },
        onFailure: (error: unknown) => {
          if (error instanceof Error) {
            onFailure(error);
            reject(error);
            return;
          }
          const unknownError = new Error(String(error));
          onFailure(unknownError);
          reject(unknownError);
        },
        newPasswordRequired: (
          userAttributes: unknown,
          requiredAttributes: unknown
        ) => {
          onNewPasswordRequired(userAttributes, requiredAttributes);
          resolve(false);
        },
      });
    });
  }

  private newPasswordChallenge(
    request: SetPasswordRequest,
    cognitoUser: CognitoUser,
    handleLoginSuccess: (session: CognitoUserSession) => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        request.password,
        request.requiredAttributeData,
        {
          onSuccess: (session: CognitoUserSession) => {
            handleLoginSuccess(session);
            resolve();
          },
          onFailure: reject,
        }
      );
    });
  }

  public handleNewPasswordChallenge(
    request: SetPasswordRequest,
    handleLoginSuccess: (session: CognitoUserSession) => void
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: request.email,
        Pool: this._userPool,
      });
      const authenticationDetails = new AuthenticationDetails({
        Username: request.email,
        Password: request.currentPassword,
      });
      this.authenticateUser(
        cognitoUser,
        authenticationDetails,
        (session: CognitoUserSession) => {
          handleLoginSuccess(session);
          resolve();
        },
        (error: unknown) => {
          console.log("Found error", error);
          if (error instanceof Error) {
            reject(error);
            return;
          }
          reject(new Error(String(error)));
        },
        async () => {
          try {
            await this.newPasswordChallenge(
              request,
              cognitoUser,
              handleLoginSuccess
            );
            resolve();
          } catch (error: unknown) {
            console.log("Found error", error);
            if (error instanceof Error) {
              reject(error);
              return;
            }
            reject(new Error(String(error)));
          }
        }
      );
    });
  }

  public resetPassword(request: ResetPasswordRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error("Failed to get current user."));
        return;
      }
      cognitoUser.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (!!error || !session?.isValid()) {
            reject(error ?? new Error("Failed to get current session."));
            return;
          }
          cognitoUser.changePassword(
            request.oldPassword,
            request.newPassword,
            (error?: Error) => {
              if (error) {
                reject(error);
                return;
              }
              resolve();
            }
          );
        }
      );
    });
  }

  public login(
    loginRequest: LoginRequest,
    handleLoginSuccess: (session: CognitoUserSession) => void,
    handleNewPasswordRequired: (
      userAttributes: unknown,
      requiredAttributeData: unknown,
      email: string,
      currentPassword: string
    ) => void
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: loginRequest.email,
        Password: loginRequest.password,
      });
      const cognitoUser = new CognitoUser({
        Username: loginRequest.email,
        Pool: this._userPool,
      });
      this.authenticateUser(
        cognitoUser,
        authenticationDetails,
        (session: CognitoUserSession) => {
          handleLoginSuccess(session);
          resolve(true);
        },
        (error: unknown) => {
          if (error instanceof Error) {
            reject(error);
            return;
          }
          reject(new Error(String(error)));
        },
        async (userAttributes: unknown, requiredAttributes: unknown) => {
          handleNewPasswordRequired(
            userAttributes,
            requiredAttributes,
            loginRequest.email,
            loginRequest.password
          );
          resolve(false);
        }
      )
        .then((value: boolean) => {
          resolve(value);
        })
        .catch((reason: unknown) => {
          if (reason instanceof Error) {
            reject(reason);
            return;
          }
          throw new Error(String(reason));
        });
    });
  }

  public logout(onSuccess: () => void): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser();
      if (!cognitoUser) {
        // User is not in localStorage
        onSuccess();
        resolve();
        return;
      }
      cognitoUser.getSession(
        async (_error: Error | null, session: CognitoUserSession | null) => {
          if (!session?.isValid()) {
            AuthService.localLogout(cognitoUser);
            onSuccess();
            resolve();
            return;
          }
          const accessToken = session.getAccessToken().getJwtToken();
          try {
            await logout(accessToken);
            AuthService.localLogout(cognitoUser);
            onSuccess();
            resolve();
          } catch (error: unknown) {
            if (error instanceof AxiosError && error.response?.status === 401) {
              AuthService.localLogout(cognitoUser);
              onSuccess();
              resolve();
              return;
            }
            if (error instanceof Error) {
              console.error(error);
              reject(error);
              return;
            }
            reject(new Error(String(error)));
          }
        }
      );
    });
  }

  private static localLogout(cognitoUser: CognitoUser): void {
    cognitoUser.signOut();
  }

  public async getSSOUrl(provider: SSOProvider): Promise<string> {
    const config = await loadConfig();
    return `${config.CognitoDomain}/oauth2/authorize?${new URLSearchParams({
      identity_provider: provider,
      redirect_uri: config.CognitoCallback,
      response_type: "code",
      client_id: config.CognitoAppClientId,
      scope: "openid",
    })}`;
  }

  public sessionExist(error: Error | null, session: CognitoUserSession | null) {
    if (!!error || !session?.isValid()) {
      return { doesSessionStillExist: false, decoded: null };
    }
    const idToken = session.getIdToken();
    const decoded = idToken.decodePayload() as TokenPayload;
    if (new Date() >= new Date(decoded.exp * 1000)) {
      return { doesSessionStillExist: false, decoded: null };
    }
    return { doesSessionStillExist: true, decoded: decoded };
  }

  /* istanbul ignore next */
  public static getInstance(config: Config): AuthService {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService(config);
    }
    return AuthService._instance;
  }
}
