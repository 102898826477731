import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AxiosError } from "axios";
import {
  QueryClient,
  QueryClientProvider,
  type QueryClientConfig,
  QueryCache,
} from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import ConfigProvider from "./context/ConfigContext";
import router from "./routes/router";
import { theme } from "./theme/theme";
import { AuthError } from "./services/AuthService";

export const IGNORED_STATUS_CODES = [400, 403, 404];
export const MAX_RETRIES: number = 3;

export const queryClientConfig: QueryClientConfig = {
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      if (error instanceof AuthError) {
        const statusCode = error.statusCode;
        if (statusCode === 401 || statusCode === 440) {
          console.log(
            "Unauthorized or session expired, redirecting to login..."
          );
          window.location.href = "/440";
        } else {
          console.error("General error:", error);
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        if (failureCount >= MAX_RETRIES) {
          return false;
        }
        if (
          error instanceof AxiosError &&
          IGNORED_STATUS_CODES.includes(Number(error.response?.status ?? -1))
        ) {
          return false;
        }
        return true;
      },
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router} />
            </LocalizationProvider>
          </ThemeProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
