import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import emailDisplayStyles from "./EmailDisplayStyles";
import { useEmailTranscript } from "../../../hooks/useInteraction";

interface EmailDisplayProps {
  fileUrl: string | undefined;
}

const EmailDisplay = ({ fileUrl }: EmailDisplayProps) => {
  const {
    data: emailContent,
    isLoading,
    isError,
    error,
  } = useEmailTranscript(fileUrl);

  if (isLoading) return <div>Loading transcript...</div>;
  if (isError || !emailContent) {
    console.error(`Error fetching transcript: ${error?.message}`);
    return (
      <div>Error fetching transcript. Please try again or contact support.</div>
    );
  }
  const formattedBody = emailContent.body.replace(/\\r\\n/g, "\n");

  return (
    <Paper elevation={3} sx={emailDisplayStyles.paper}>
      <Box sx={emailDisplayStyles.header}>
        <Typography variant="h6" sx={emailDisplayStyles.subject}>
          {emailContent.subject}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={emailDisplayStyles.timestamp}
        >
          {new Date(emailContent.sentTime).toLocaleString()}
        </Typography>
      </Box>
      <Stack spacing={1} sx={{ marginBottom: 2 }}>
        <Box sx={emailDisplayStyles.row}>
          <Typography variant="subtitle2" sx={emailDisplayStyles.label}>
            From:
          </Typography>
          <Typography variant="body2" sx={emailDisplayStyles.boldedValue}>
            {emailContent.from}
          </Typography>
        </Box>
        <Box sx={emailDisplayStyles.row}>
          <Typography variant="subtitle2" sx={emailDisplayStyles.label}>
            To:
          </Typography>
          <Typography variant="body2" sx={emailDisplayStyles.value}>
            {emailContent.to?.join(", ")}
          </Typography>
        </Box>
        {emailContent.cc?.length > 0 && (
          <Box sx={emailDisplayStyles.row}>
            <Typography variant="subtitle2" sx={emailDisplayStyles.label}>
              CC:
            </Typography>
            <Typography variant="body2" sx={emailDisplayStyles.value}>
              {emailContent.cc.join(", ")}
            </Typography>
          </Box>
        )}
        {emailContent.bcc?.length > 0 && (
          <Box sx={emailDisplayStyles.row}>
            <Typography variant="subtitle2" sx={emailDisplayStyles.label}>
              BCC:
            </Typography>
            <Typography variant="body2" sx={emailDisplayStyles.value}>
              {emailContent.bcc.join(", ")}
            </Typography>
          </Box>
        )}
      </Stack>
      <Divider sx={{ marginBottom: 3 }} />
      <Box sx={emailDisplayStyles.messageBody}>
        <Typography variant="body1">{formattedBody}</Typography>
      </Box>
    </Paper>
  );
};

export default EmailDisplay;
