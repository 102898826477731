import { Box, Grid } from "@mui/material";
import MediaTypeAnalyticsChart from "./MediaTypeAnalyticsChart";
import AnalyticWidget from "./AnalyticWidget";
import InteractionAnalyticsBarChart from "./InteractionAnalyticsBarChart";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useState, useEffect } from "react";
import { useDashboardData } from "../../hooks/useDashboardData";
import { useParams } from "react-router-dom";
import { InteractionByMediaType, InteractionsByDay } from "../../types/dashboard/Dashboard";

export const formatTime = (ms:number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}h ${minutes}m ${seconds}s`;

  }
  const Dashboard = () => {
  const [countLegalHold, setCountLegalHold] = useState<number | undefined>();
  const [countInteractionsExpiring, setCountInteractionsExpiring] = useState<
    number | undefined
  >();
  const [averageDuration, setAverageDuration] = useState<string | undefined>();
  const [interactionsByDay, setInteractionsByDay] = useState<InteractionsByDay|undefined>();
  const { customerId } = useParams();
  const { data, isLoading } = useDashboardData(customerId);
  const [mediaTypeInteraction, setMediaTypeInteraction] = useState<
    InteractionByMediaType[] | null>([]);

  
  useEffect(() => {
    if (isLoading || !data) return;

    const averageDurationInUnits = formatTime(data.averageDuration);

    setCountLegalHold(data.countLegalHold);
    setCountInteractionsExpiring(data.countInteractionsExpiring);
    setAverageDuration(averageDurationInUnits);
    setMediaTypeInteraction(data.interactionByType);
    setInteractionsByDay(data.interactionsByDay)

  }, [isLoading, data]);


  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <AnalyticWidget
            title="Interactions in Legal Hold"
            total={countLegalHold}
            color="secondary"
            icon={<DoNotDisturbOnIcon />}
            isLoading={isLoading}
            tooltipContent="The total interactions currently in legal hold; no changes can be made"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AnalyticWidget
            title="Purging in the Next 30 Days"
            total={countInteractionsExpiring}
            color="secondary"
            icon={<SubscriptionsIcon />}
            isLoading={isLoading}
            tooltipContent="Number of interactions with retention date expiring in the next 30 days"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AnalyticWidget
            title="Average Interaction Duration"
            total={averageDuration}
            color="secondary"
            icon={<FunctionsIcon />}
            isLoading={isLoading}
            tooltipContent="Average duration of an interaction"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <InteractionAnalyticsBarChart
            interactionsByDay={interactionsByDay}
            isBarChartLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MediaTypeAnalyticsChart 
          data={mediaTypeInteraction}
          isLoading={isLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
