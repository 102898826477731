import React, {useEffect, useState} from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { InteractionType } from "../../types/interaction/Interaction";
import AudioPlayer from "./Audio/AudioPlayer";
import MediaPlayer from "./Video/VideoPlayer";
import ChatDisplay from "./Chat/ChatDisplay";
import EmailDisplay from "./Email/EmailDisplay";
import Stack from "@mui/material/Stack";
import { GridColDef, DataGrid } from "@mui/x-data-grid";

interface InteractionMetadata {
  key: string;
  value: unknown;
  source: "Continuity Replay" | "Source";
  id: string;
}

interface MediaDisplaySelectorProps {
  interactionType: InteractionType;
  playerInteractionId: string | undefined;
  data: any;
  metadata: Record<string, unknown> | undefined;
  agentId: string | undefined;
  isLoading: boolean;
  isPlaying: boolean;
  setIsPlaying: (playing: boolean) => void;
  mediaDrawerWidth?: number;
  handleOpenInNewWindow?: (playerInteractionId: string) => void;
}

const MediaDisplaySelector: React.FC<MediaDisplaySelectorProps> = ({
  interactionType,
  playerInteractionId,
  data,
  agentId,
  isLoading,
  isPlaying,
  setIsPlaying,
  mediaDrawerWidth,
  handleOpenInNewWindow,
  metadata,
}) => {

  const MediaDisplay: Record<InteractionType, React.ComponentType<any>> = {
    [InteractionType.Video]: MediaPlayer,
    [InteractionType.AudioAndVideo]: MediaPlayer,
    [InteractionType.Audio]: AudioPlayer,
    [InteractionType.Chat]: ChatDisplay,
    [InteractionType.Email]: EmailDisplay,
  };

  const SelectedMediaDisplay = MediaDisplay[interactionType];

  const mediaProps = (() => {
    switch (interactionType) {
      case InteractionType.Audio:
        return {
          audioPath: data?.temporaryUrl,
          isPlaying,
          setIsPlaying,
          isLoading,
          playerInteractionId,
          handleOpenInNewWindow,
        };
      case InteractionType.Chat:
        return {
          fileUrl: data?.temporaryUrl,
          agentId,
        };
      case InteractionType.AudioAndVideo:
      case InteractionType.Video:
        return {
          mediaUrl: data?.temporaryUrl,
          playerInteractionId,
          isUrlLoading: isLoading,
          isPlaying,
          setIsPlaying,
          mediaDrawerWidth,
          handleOpenInNewWindow,
        };
      case InteractionType.Email:
        return {
          fileUrl: data?.temporaryUrl,
        };
      default:
        return {};
    }
  })();

  const [isMetadataVisible, setIsMetadataVisible] = useState<boolean>(false);
  const [interactionData, setInteractionData] = useState<InteractionMetadata[]>(
    []
  );

  useEffect(() => {
    if (!metadata) return;

    const common = Object.keys(metadata).map(
      (key: string) =>
        ({
          id: key,
          source: key.startsWith("src_") ? "Source" : "Continuity Replay",
          key: key.startsWith("src_") ? key.replace("src_", "") : key,
          value: metadata[key],
        } as InteractionMetadata)
    );

    common.sort(
      (a, b) => b.source.localeCompare(a.source) || a.key.localeCompare(b.key)
    );
    setInteractionData(common);
    setIsMetadataVisible(false);
  }, [metadata]);

  const columns: GridColDef[] = [
    {
      field: "key",
      headerName: "Key",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
    },
  ];

  return (
    <>
      <Box>
        {SelectedMediaDisplay && <SelectedMediaDisplay {...mediaProps} />}
      </Box>
      {isMetadataVisible && 
      <Box padding={2}>
        <Stack
          spacing={{ xs: 2 }}
          direction="row"
          useFlexGap
          sx={{
            flexWrap: "wrap",
            margin: 3,
            justifyContent: "center",
          }}
        >
          <Card>
            <CardContent>
              <Typography variant="subtitle2">Agent Name</Typography>
              <Typography variant="h6">
                {(metadata as { agentName?: string })?.agentName}
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="subtitle2">Phone Number</Typography>
              <Typography variant="h6">
                {(metadata as { phoneNumber?: string })?.phoneNumber}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography variant="subtitle2">Source Id</Typography>
              <Typography variant="h6">
                {(metadata as { sourceId?: string })?.sourceId}
              </Typography>
            </CardContent>
          </Card>
        </Stack>
        <DataGrid columns={columns} rows={interactionData} density="compact" />
      </Box>}
    </>
  );
};

export default MediaDisplaySelector;
