import { useContext } from "react";
import { AuthContext, IAuthContext } from "../context/AuthContext";
import { ReactNode } from "react";
import { useAlert } from "../context/AlertContext";
import { Unauthorized } from "../components/permissions/Unauthorized";
import { Navigate } from "react-router-dom";

interface PermissionsRestrictedRouteProps {
  children: ReactNode;
  requiredPermission?: string;
  resource?: string;
}

const PermissionsRestrictedRoute = ({
  children,
  requiredPermission,
  resource,
}: PermissionsRestrictedRouteProps) => {
  const { user, validating, authenticated } = useContext(
    AuthContext
  ) as IAuthContext;
  const showAlert = useAlert();

  if (validating) {
    return <>Loading...</>;
  }

  if (!authenticated || !user) {
    return <Navigate to="/login" />;
  }

  if (requiredPermission && resource) {
    if (!user.hasPermission(`${resource}:${requiredPermission}`)) {
      showAlert("You are not authorized to view this content");
      return <Unauthorized />;
    }
  }
  return children;
};

export default PermissionsRestrictedRoute;
