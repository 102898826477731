import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColumnVisibilityModel,
  GridInitialState,
  GridPaginationModel,
  GridSortModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useEffect, useState, useCallback } from "react";
import {
  getVisibleColumnsFromLocalStorage,
  saveVisibleColumnsToLocalStorage,
} from "../../../helpers/columnVisibility";
import ReactDOM from "react-dom";
import GridPagination, {
  GridPaginationProps,
} from "../../pagination/GridPagination";
import { GridPaginationChangePageAction } from "../../pagination/PaginationActions";
import { debounce } from 'lodash'; 
import { RowSelectorMode } from "../../../types/datatable/RowSelectorMode";

declare module "@mui/x-data-grid" {
  interface PaginationPropsOverrides extends GridPaginationProps {}
}

export interface DataTableProps
  extends Omit<DataGridProps, "onSortModelChange"> {
  tableId?: string;
  initialStateValue?: GridInitialState;
  approximate?: boolean;
  startApproximatingAt?: number;
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => Promise<GridSortModel> | GridSortModel;
  rowSelectorMode?: RowSelectorMode; // this is used to clear out the checkbox in the header row if it's not needed
  onNextPage?: GridPaginationChangePageAction;
  onPreviousPage?: GridPaginationChangePageAction;
  refresh?: () => void | Promise<void>;
  pageIndex?: number;
}
const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  pageSize: 100,
  page: 0,
};

const AUTOSIZE_OPTIONS = {
  expand: false,
  includeHeaders: true,
  includeOutliers: true,
};

const DataTable = ({
  tableId,
  rows,
  refresh,
  initialStateValue,
  onPaginationModelChange,
  onSortModelChange,
  slots = {},
  slotProps = {},
  loading,
  approximate = false,
  startApproximatingAt = 0,
  paginationMode = "client",
  sortingMode = "client",
  onNextPage,
  onPreviousPage,
  rowSelectorMode = RowSelectorMode.SINGLE,
  pageIndex,
  ...props
}: DataTableProps) => {
  const newApiRef = useGridApiRef();
  const apiRef = props.apiRef ?? newApiRef;
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    DEFAULT_PAGINATION_MODEL
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    props.initialState?.sorting?.sortModel ?? []
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    props.columnVisibilityModel
  );


  const handleRefresh = useCallback(() => {
    if (refresh) {
      refresh();
      console.log("refreshing...");
    }
  }, [refresh]);


  const [hasAutoSized, setHasAutoSized] = useState(false);

   const autosizeColumns = useCallback(() => {
    ReactDOM.flushSync(() => {
      apiRef.current.autosizeColumns(AUTOSIZE_OPTIONS);
    });
    setHasAutoSized(true);

    handleRefresh();
  }, [apiRef, handleRefresh]);
 
   useEffect(() => {
    const debouncedAutosize = debounce(autosizeColumns, 500);
    
    if (!loading && rows && rows.length > 0 && !hasAutoSized) {
      debouncedAutosize();
    }

    return () => {
      debouncedAutosize.cancel();
    };
  }, [loading, rows, hasAutoSized, autosizeColumns]); 



  useEffect(() => {
    setColumnVisibilityModel(
      getVisibleColumnsFromLocalStorage(
        tableId,
        initialStateValue?.columns?.columnVisibilityModel
      )
    );
  }, [tableId, initialStateValue?.columns?.columnVisibilityModel]);

  const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    saveVisibleColumnsToLocalStorage(tableId, model);
  };



  return (
    <div style={{ height: "97%", display: "flex", flexDirection: "column" }}>
      <DataGrid
        {...props}
        slots={{
          toolbar: GridToolbar,
          pagination: GridPagination,
          ...slots,
        }}
        slotProps={{
          ...slotProps,
          pagination: {
            pageIndex,
            approximate,
            startApproximatingAt,
            estimatedRowCount: props.estimatedRowCount,
            rowCount: props.rowCount,
            onNextPage,
            onPreviousPage,
          },
        }}
        rows={rows}
        editMode="row"
        checkboxSelection={rowSelectorMode === RowSelectorMode.MULTIPLE} 
        disableRowSelectionOnClick
        pageSizeOptions={[100]}
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        paginationModel={props.paginationModel ?? paginationModel}
        onPaginationModelChange={(
          model: GridPaginationModel,
          details: GridCallbackDetails
        ) => {
          setPaginationModel(model);
          onPaginationModelChange?.(model, details);
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        initialState={{
          ...props.initialState,
          pagination: {
            paginationModel: DEFAULT_PAGINATION_MODEL,
            ...props.initialState?.pagination,
          },
        }}
        sortModel={sortModel}
        onSortModelChange={async (
          model: GridSortModel,
          details: GridCallbackDetails
        ) => {
          const newSortModel = await onSortModelChange?.(model, details);
          setSortModel(newSortModel ?? model);
        }}
        autosizeOptions={AUTOSIZE_OPTIONS}
        loading={loading}
        apiRef={apiRef}
      />
    </div>
  );
};

export default DataTable;
