import { Typography, Box } from "@mui/material";

const Unauthorized = () => {
  return (
    <Box display="flex-col" textAlign="center">
      <Typography variant="h2">403</Typography>
      <Typography sx={{paddingLeft:1}}>You are not authorized to view this content.</Typography>
    </Box>
  );
};

export { Unauthorized };
