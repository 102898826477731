import { Box } from '@mui/material';
import ClosePlayerButton from '../ClosePlayerButton';
import NewWindowButton from '../NewWindowButton';

interface HeaderControlsProps {
  closePlayer: () => void;
  isLoading: boolean;
  playerInteractionId: string | undefined;
  handleOpenInNewWindow?: (
    playerInteractionId: string,
    closeWindow?: () => void
  ) => void;
}

const HeaderControls = ({ closePlayer, playerInteractionId, handleOpenInNewWindow, isLoading}: HeaderControlsProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <ClosePlayerButton closePlayer={closePlayer} />
      <NewWindowButton
        playerInteractionId={playerInteractionId}
        handleOpenInNewWindow={handleOpenInNewWindow}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default HeaderControls;
