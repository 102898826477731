import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useInteractionFileDownload } from "../../hooks/useInteraction";
import LoadingIndicator from "../../components/common/LoadingIndicator/LoadingIndicator";
import { useAlert } from "../../context/AlertContext";

export interface DownloadInteractionFileButtonProps extends IconButtonProps {
  customerId: string;
  interactionId: string;
}

const DownloadInteractionFileButton = ({
  customerId,
  interactionId,
  ...props
}: DownloadInteractionFileButtonProps) => {
  const showAlert = useAlert();
  const {
    mutateAsync: downloadFileAsync,
    status,
    error,
  } = useInteractionFileDownload();
  const isLoading = status === 'pending';
  const handleDownload = async () => {
    try {
      await downloadFileAsync({
        customerId,
        interactionId,
      });
      showAlert("File downloaded successfully.", "success");
    } catch (error: unknown) {
      showAlert("Error while downloading file.", "error");
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <IconButton {...props} onClick={handleDownload}>
      <DownloadIcon className={error ? "text-red-500" : ""} />
    </IconButton>
  );
};

export default DownloadInteractionFileButton;
