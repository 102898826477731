import { HTMLAttributes, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { Interactions } from "../../api/interaction";
import SearchDrawerForm from "./SearchDrawerForm";
import { SearchFormValues } from "../../types/search/SearchFormValues";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export interface SearchDrawerProps extends HTMLAttributes<HTMLDivElement> {
  customerId: string;
  setSearchValues: (values: SearchFormValues) => void;
  refetch:(
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<Interactions, Error>>;
  closePlayer: () => void;
  openFilterDrawer: boolean;
  setOpenFilterDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  filterDrawerWidth: number;
  setFilterDrawerWidth: React.Dispatch<React.SetStateAction<number>>;
  handlePaginationModelChange: (model: any) => void;
}
export default function SearchDrawer({
  setSearchValues,
  refetch,
  customerId,
  closePlayer,
  openFilterDrawer,
  setOpenFilterDrawer,
  filterDrawerWidth,
  setFilterDrawerWidth,
  handlePaginationModelChange
}: SearchDrawerProps) {
  const theme = useTheme();
  const minFilterDrawerWidth = 300;
  const maxFilterDrawerWidth = 600;

  const handleDrawerClose = () => {
    setOpenFilterDrawer(false);
  };

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const newWidth = e.clientX + 20 - document.body.offsetLeft

      if (newWidth >= minFilterDrawerWidth && newWidth <= maxFilterDrawerWidth) {
        setFilterDrawerWidth(newWidth);
      }
    },
    [minFilterDrawerWidth, maxFilterDrawerWidth, setFilterDrawerWidth]
  );

  return (
    <Box sx={{ display: "flex", overflow: "auto" }}>
      <Drawer
        sx={{
          zIndex:1050,
          width: filterDrawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: filterDrawerWidth,
            boxSizing: "border-box",
            p: 3,
            paddingTop: 10,
          },
        }}
        variant="persistent"
        anchor="left"
        open={openFilterDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Box
          onMouseDown={() => handleMouseDown()}
          sx={{
            width: "8px",
            cursor: "grab",
            padding: "4px 0 0",
            borderTop: "1px solid #ddd",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: "#f4f7f9",
            boxShadow: 5,
            userSelect: "none",
          }}
        />
        <Box sx={{ 
          overflow: "auto" 
          }}>
          <SearchDrawerForm
            customerId={customerId}
            setSearchValues={setSearchValues}
            closePlayer={closePlayer}
            refetch={refetch}
            setOpen={setOpenFilterDrawer}
            handlePaginationModelChange={handlePaginationModelChange}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
