import {
  gridPaginationModelSelector,
  gridRowCountSelector,
  gridRowsLoadingSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useGridSelector } from "@mui/x-data-grid/internals";
import PaginationInfo from "./PaginationInfo";
import PaginationActions, {
  GridPaginationChangePageAction,
} from "./PaginationActions";
import { useEffect, useState } from "react";

export interface GridPaginationProps {
  className?: string;
  approximate?: boolean;
  startApproximatingAt?: number;
  estimatedRowCount?: number;
  rowCount?: number;
  onNextPage?: GridPaginationChangePageAction;
  onPreviousPage?: GridPaginationChangePageAction;
  pageIndex?: number;
}

const GridPagination = ({
  className = "",
  approximate = false,
  startApproximatingAt = 0,
  estimatedRowCount,
  rowCount,
  onNextPage,
  onPreviousPage,
  pageIndex,
}: GridPaginationProps) => {
  const apiRef = useGridApiContext();
  const [page, setPage] = useState<number>(0);
  const muiRowCount = useGridSelector(apiRef, gridRowCountSelector);
  const { pageSize } = useGridSelector(apiRef, gridPaginationModelSelector);
  const isLoading = useGridSelector(apiRef, gridRowsLoadingSelector);

  useEffect(() => {
    setPage(pageIndex ?? 0);
  }, [pageIndex]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    onNextPage?.();
  };

  const handlePreviousPage = () => {
    setPage((prev) => prev - 1);
    onPreviousPage?.();
  };

  const actualRowCount = estimatedRowCount
    ? undefined
    : rowCount ?? muiRowCount;

  return (
    <div className={`${className} w-min text-nowrap flex-nowrap h-full flex`}>
      <PaginationInfo
        className="pr-2"
        page={page}
        rowCount={actualRowCount}
        estimatedRowCount={estimatedRowCount}
        rowsPerPage={pageSize}
        approximate={approximate}
        startApproximatingAt={startApproximatingAt}
        isLoading={isLoading}
      />
      <PaginationActions
        page={page}
        rowCount={actualRowCount}
        estimatedRowCount={estimatedRowCount}
        rowsPerPage={pageSize}
        isLoading={isLoading}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </div>
  );
};

export default GridPagination;
