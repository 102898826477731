import { useState } from "react";
import { Chip, TextField, Autocomplete, Paper } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import Interaction from "../../../types/interaction/Interaction";
import { useInteractionTags } from "../../../hooks/useInteraction";

export interface EditTagsCellProps
  extends GridRenderEditCellParams<Interaction, string[]> {
    customerId?: string;
  }

const EditTagsCell = (params: EditTagsCellProps) => {
  const { id, value = [], field, api, colDef, row, customerId } = params;

  const {data: allAvailableTags = []} = useInteractionTags(customerId); // Replace with the actual list of available tags
  const [inputValue, setInputValue] = useState<string>("");

  const handleTagsChange = (newTags: string[]) => {
    api.setEditCellValue({ id, field, value: newTags });
  };

  const handleNewTag = () => {
    if (inputValue.trim() && !value.includes(inputValue)) {
      const updatedTags = [...value, inputValue];
      handleTagsChange(updatedTags);

      setInputValue("");
    }
  };

  const filteredOptions = [
    ...allAvailableTags.filter((tag) => !row.tags.includes(tag) && !value.includes(tag)),
  ];
  
  if (
    inputValue &&
    !allAvailableTags.includes(inputValue) &&
    !row.tags.includes(inputValue) &&
    !value.includes(inputValue)
  ) {
    filteredOptions.push(`Add '${inputValue}'`);
  }

  const editComponentWidth = colDef.width;

  return (
    <Paper
      elevation={8}
      sx={{
        position: "absolute",
        zIndex: 1,
        boxSizing: "border-box",
        width: `${editComponentWidth}px`,
      }}
    >
      <Autocomplete
        multiple
        options={filteredOptions}
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={(event, newTags) => {
          const lastTag = newTags[newTags.length - 1];
          if (lastTag === `Add '${inputValue}'`) {
            handleNewTag();
          } else {
            handleTagsChange(newTags);
          }
        }}
        renderTags={(tagValues, getTagProps) =>
          tagValues.map((tag, index) => (
            <Chip label={tag} {...getTagProps({ index })} size="small" />
          ))
        }
        renderInput={(params) => (
          <TextField
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "small",
              },
            }}
            {...params}
            placeholder="Type to add..."
          />
        )}
        freeSolo
        disableCloseOnSelect
      />
    </Paper>
  );
};

export default EditTagsCell;
